<template>
  <a-form :form="form">
    <mcma-card title="Localisation du site" class="mcma-card-in-tab" :tips="true" data="Le <strong>nom</strong> du site (ou le nom du point de livraison) : il s’agit du nom que vous souhaitez voir apparaitre sur vos factures (40 caractères max)
<strong>L’adresse</strong> du point de livraison (adresse, code postal, commune)">
      <template #describe>
        <span v-if="!site.id">
          Tant que les informations d'identification du site que vous voulez créer ne sont pas complétées, vous ne
          pouvez pas accéder aux autres onglets.
        </span>
        <span v-else>Vous pouvez modifier les informations d'identification du site autant que souhaité, le temps que
          celui-ci
          n'est pas certifié.</span>
      </template>

      <a-row type="flex" :gutter="12">
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="nom" :defaultValue="site.nom"
          placeholder="Mon site n°5" getFlow="name::sites" getPanel="name::Localisation" getField="type::siteName"
          :required="true && siteStateDeleted" @change="updateSiteEditing(form.getFieldsValue())" rule="isLimitedLibelle"/>
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codeSite" :defaultValue="site.codeSite"
          placeholder="123456789" :maxlength="20" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::siteCode" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="numeroVoie"
          :defaultValue="site.numeroVoie" placeholder="" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::voieNumber" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="nomVoie"
          :defaultValue="site.nomVoie || site.adresse" placeholder="12 rue de la tour eiffel" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::voieName" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="adresse" :defaultValue="site.adresse"
          placeholder="12 rue de la tour eiffel" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::adress" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codePostal"
          :defaultValue="site.codePostal" placeholder="92000" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::postalCode" rule="isZipCode" :required="true && siteStateDeleted"
          @change="updateEld(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="commune" :defaultValue="site.commune"
          placeholder="PARIS" :uppercase="true" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::town" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codeInsee" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::codeInsee" :defaultValue="site.codeInsee" placeholder="12345"
          rule="isCodeInsee" @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>

    <mcma-card title="Fluide et Statut" class="mcma-card-in-tab">
      <a-row type="flex" :gutter="12">
        <mcma-field type="select" :form="form" :wasSubmit="wasSubmit" :disabled="!!site.fluide" fieldName="fluide"
          disableValue="Sélectionnez un fluide" label="Fluide"
          :extraLabel="!site.fluide ? 'non modifiable une fois sauvegardé' : null" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::fluid" :required="true && siteStateDeleted"
          @change="updateEld(form.getFieldsValue())" />
        <mcma-field type="select" :tips="true" data="Chaque site possède un <strong>« statut »</strong> : 
<strong>« Actuellement en service »</strong> : statut par défaut ; le site est en service et rattaché à votre entité
<strong>« Branchement provisoire »</strong> : cas particulier, le n° de PCE est éphémère 
<strong>« En cours de raccordement »</strong> : site en attente de mise en service (date d’effet postérieure à celle du marché) 
<strong>« A supprimer »</strong> : site hors périmètre ou résilié, à exclure de ce marché
<strong>« Site à ajouter au périmètre »</strong> : site relevant de votre entité et absent du périmètre communiqué à Engie 
<strong>« A transférer vers autre membre »</strong> : site à transférer à une autre entité du même marché
Pour supprimer un site, il suffit de le passer au statut « A supprimer », il restera visible dans la liste 
(et pourra être restauré le cas échéant). " :form="form" :wasSubmit="wasSubmit"
          :defaultValue="!site.added ? 'NOUVEAU' : site.status" label="Statut" disableValue="Sélectionnez un statut"
          getFlow="name::sites" getPanel="name::Localisation" getField="type::status" :required="true"
          @change="onChangeStatus($event)" />
        <mcma-field v-if="form.getFieldValue('status') === 'RESILIATION' || form.getFieldValue('status') === 'REJETE'" type="select" :form="form" :wasSubmit="wasSubmit"
          :defaultValue="site.motifResiliation" label="Motif"
          getFlow="name::sites" getPanel="name::Localisation" getField="type::motifResiliation"
          />
        <mcma-field type="date" class="mt10" :tips="true" data="<strong>Date d’activation</strong> : date à laquelle le site doit être intégré au marché. Cette donnée est déjà pré-remplie car communiquée dans le cadre de l’appel d’offres. C’est en général la date d’effet du 
marché – sauf cas particuliers : 
- date d’effet différée : à préciser dans ce cas, 
- site en cours de raccordement : indiquer la date prévisionnelle de mise en service.
<strong>Date de résiliation</strong> : date de fin du marché (valeur par défaut), à rectifier pour les sites dont une date 
de mise hors service est déjà programmée." :form="form" :wasSubmit="wasSubmit" :defaultValue="site.dateActivation"
          :disableDateBefore="operation.dateBascule" fieldName="dateActivation"
          label="Sélectionnez la date d'activation" placeholder="date d'activation" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::dateActivation" :extraLabel="`Le marché débutera le ${$d(
    operation.dateBascule
  )}. Si votre site bénéficie actuellement d'un contrat au Tarif règlementé de vente d'électricité (TRV) il pourra intégrer le marché dès le ${$d(
    operation.dateBascule
  )}. Si votre site est actuellement en contrat en offre de marché, veillez à bien indiquer la date d'échéance de votre contrat en cours (postérieure au ${$d(
    operation.dateBascule
  )}) sous peine de vous voir  infliger des pénalités par votre fournisseur actuel pour rupture  anticipée.`"
          :required="true && siteStateDeleted" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="date" class="mt10" :form="form" :wasSubmit="wasSubmit"
          :disabled="!form.getFieldValue('dateActivation')" :disableDateBefore="form.getFieldValue('dateActivation')"
          :defaultValue="site.dateResiliation" fieldName="dateResiliation" label="Sélectionnez la date de resiliation"
          placeholder="date de resiliation" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::dateFinContrat" @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>

    <mcma-card title="Référence du site" class="mcma-card-in-tab">
      <a-row type="flex" :gutter="12">
        <mcma-field type="select" :form="form" :wasSubmit="wasSubmit" fieldName="distributeur" label="Distributeur"
          id="distributeur" :required="true && siteStateDeleted"
          extraLabel=" Ajouter préalablement le fluide et le code postal" :disabled="getDistribution(form.getFieldsValue()).length === 0 || (site.distributeur && site.id && !site.added)
    " :defaultList="getDistribution(form.getFieldsValue())"
          @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.rae" placeholder="123456789"
          :label="raeLabel(form)" :disabled="site.rae && site.id && !site.added" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::rae" :extraLabel="getRaeExtraLabel(form)" :required="true"
          :rule="getRaeRule(form)" @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>

    <mcma-card title="Classification budgétaire" class="mcma-card-in-tab">
      <a-row type="flex" :gutter="12">
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codeGroupement"
          :defaultValue="site.codeGroupement" placeholder="123456789" :maxlength="20" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::codeGroupement"
          :extraLabel="'*Indiquez ici une référence comptable (max. 40 caractères alphanumériques) que vous souhaitez voir apparaître pour chaque site concerné sur chacune des factures (individuelles ou groupées). '"
          @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.codeImputation"
          disableValue="Sélectionnez le code imputation budgetaire" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::codeImputation" @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>
  </a-form>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
import Axios from "@/util/Axios"
import { pick } from "@/util/Helpers"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import Distribution from "@/components/mcma/common/Fields/Jsons/Distribution.json"
import { getClientTechInfos } from "@/util/Helpers/Navigator"
import Tips from "@/components/mcma/help/contactUs/Tips"
export default {
  name: "SiteEditLocalisation",
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      siteStateDeleted: null,
    }
  },
  components: {
    McmaCard,
    McmaField,
    Tips,
  },
  props: {
    site: Object,
  },
  computed: {
    ...mapState(["membre", "operation", "operations"]),
    getDistribution() {
      return (fieldsValues) => {
        if (fieldsValues.fluide && fieldsValues.codePostal && fieldsValues.codePostal.length === 5) {
          const departmentCode = fieldsValues.codePostal.toString().substring(0, 2)
          if (!Distribution[fieldsValues.fluide][departmentCode]) {
            return [...Distribution[fieldsValues.fluide].default]
          }
          return [...Distribution[fieldsValues.fluide].default, ...Distribution[fieldsValues.fluide][departmentCode]]
        } else {
          return []
        }
      }
    },
    getRaeRule() {
      return (form) => {
        if (this.raeLabel(form) === "RAE") {
          return "isRAE"
        } else if (this.raeLabel(form) === "RAE (Distributeur)") {
          return "isELD_RAE"
        } else if (this.raeLabel(form) === "PCE") {
          return "isPCE"
        } else if (this.raeLabel(form) === "PCE (Distributeur)") {
          return "isELD_PCE"
        }
        return null
      }
    },
    getRaeExtraLabel() {
      return (form) => {
        if (this.raeLabel(form) === "RAE") {
          if (this.getDistribution(form.getFieldsValue()).length === 1) {
            return "Votre RAE composé de 14 chiffres est présent sur vos factures"
          }
          return "Si votre RAE n’est pas à 14 chiffres, merci de préciser votre distributeur avant de rentrer votre RAE qui est présent sur vos factures"
        } else if (this.raeLabel(form) === "RAE (Distributeur)") {
          return "Si votre RAE n’est pas à 14 chiffres, merci de préciser votre distributeur avant de rentrer votre RAE qui est présent sur vos factures"
        }
        return null
      }
    },
    raeLabel() {
      return (form) => {
        const lastFluide = form.getFieldValue("fluide") || this.site.fluide
        const isEld =
          form.getFieldValue("distributeur") &&
          form.getFieldValue("distributeur") !== "ENEDIS" &&
          form.getFieldValue("distributeur") !== "GRDF"
        const result = lastFluide === "ELEC" ? "RAE" : lastFluide === "GAZ" ? "PCE" : "RAE / PCE"
        return isEld ? result + " (Distributeur)" : result
      }
    },
  },
  mounted() {
    console.log("this.ope   ", this.operation)
    this.initialize()
  },
  methods: {
    moment,
    updateEld(fieldsValue) {
      if (this.getDistribution(fieldsValue).length >= 1) {
        this.$nextTick(() => {
          if (!this.getDistribution(fieldsValue).includes(fieldsValue.distributeur)) {
            this.form.setFieldsValue({
              distributeur: this.getDistribution(fieldsValue)[0],
            })
          }
        })
      }
      this.updateSiteEditing(fieldsValue)
    },
    updateSiteEditing(fieldsValue) {
      this.$root.$emit("ROOT_updateSiteEditing", fieldsValue)
    },
    initialize() {
      this.form.getFieldDecorator("nom")
      this.form.getFieldDecorator("codeSite")
      this.form.getFieldDecorator("rae")
      this.form.getFieldDecorator("fluide")
      this.form.getFieldDecorator("status")
      this.form.getFieldDecorator("motifResiliation")
      this.form.getFieldDecorator("refTDG")
      this.form.getFieldDecorator("refSite")
      this.form.getFieldDecorator("adresse")
      this.form.getFieldDecorator("codePostal")
      this.form.getFieldDecorator("commune")
      this.form.getFieldDecorator("codeImputation")
      this.form.getFieldDecorator("codeGroupement")
      // this.form.getFieldDecorator("commentaire");
      this.form.getFieldDecorator("dateActivation")
      this.form.getFieldDecorator("dateResiliation")
      this.form.getFieldDecorator("codeInsee")
      this.form.getFieldDecorator("distributeur")
      let editSite = pick(this.site, [
        "nom",
        "codeSite",
        "rae",
        "fluide",
        "status",
        "motifResiliation",
        "refTDG",
        "refSite",
        "adresse",
        "codePostal",
        "commune",
        "codeImputation",
        "codeGroupement",
        // 'commentaire',
        "codeInsee",
        "distributeur",
      ])
      editSite = this.$mergeObject(this.form.getFieldsValue(), editSite)
      this.$nextTick(() => {
        this.form.setFieldsValue(editSite)
      })

      if (
        this.form.getFieldValue("status") === "INEXISTANT" ||
        this.form.getFieldValue("status") === "RESILIATION" ||
        this.form.getFieldValue("status") === "REJETE"
      ) {
        this.siteStateDeleted = false
        this.$emit("deletedStatus", false)
      } else {
        this.siteStateDeleted = true
        this.$emit("deletedStatus", true)
      }
    },
    save(site) {
      this.$emit("save", site)
    },
    submit(interlocuteurId) {
      console.log(
        `%c Site > Etape_1 > submit`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
      )
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        let siteActif = !(
          this.form.getFieldValue("status") === "INEXISTANT" ||
          this.form.getFieldValue("status") === "RESILIATION" ||
          this.form.getFieldValue("status") === "REJETE"
        )
        if (errors) {
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else if (interlocuteurId === null && siteActif) {
          this.$notification.error({
            message: "Aucun interlocuteur n'existe",
            description: `Vous devez préalablement créer un interlocuteur technique pour ce site.`,
          })
        } else {
          console.log("interlocuteur")
          if (interlocuteurId) {
            Axios("get", "api/interlocuteurs/" + interlocuteurId)
              .then((response) => {
                this.proceed(response.data.id, fieldsValue)
              })
              .catch((error) => {
                console.error("error", error)
              })
              .catch((error) => {
                console.error("error", error)
              })
          } else {
            this.proceed(null, fieldsValue)
          }
        }
      })
    },
    onChangeStatus(statusValue) {
      if (statusValue === "INEXISTANT" || statusValue === "RESILIATION" || statusValue === "REJETE") {
        this.siteStateDeleted = false
        this.$emit("deletedStatus", false)
      } else {
        this.siteStateDeleted = true
        this.$emit("deletedStatus", true)
      }
    },
    async proceed(interlocuteurId, fieldsValue) {
      const siteDisabled = ["RESILIATION", "REJETE", "INEXISTANT"]
      // extinctionNuit temporairement à 0 car pas exploité côté engie et remonte une erreur.
      const site = {
        extinctionNuit: 0,
        ongletIdentification: true,
        membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
        fluide: fieldsValue.fluide,
        nom: fieldsValue.nom == null ? "PAS DE NOM" : fieldsValue.nom,
        codeSite: fieldsValue.codeSite,
        rae: fieldsValue.rae,
        numeroVoie: fieldsValue.numeroVoie,
        nomVoie: fieldsValue.nomVoie,
        adresse: fieldsValue.adresse,
        codePostal: fieldsValue.codePostal,
        commune: fieldsValue.commune,
        codeImputation: fieldsValue.codeImputation,
        codeGroupement: fieldsValue.codeGroupement,
        distributeur: fieldsValue.distributeur,
        status: fieldsValue.status,
        motifResiliation: fieldsValue.motifResiliation,
        removed: siteDisabled.includes(fieldsValue.status) ? 1 : 0,
        dateActivation: +moment(fieldsValue.dateActivation),
        dateResiliation: +moment(fieldsValue.dateResiliation),
        added: this.site.added,
        codeInsee: fieldsValue.codeInsee,
        operationId: this.operation.id,
        interlocuteur: interlocuteurId ? process.env.VUE_APP_API_URL + "/api/interlocuteurs/" + interlocuteurId : null,
        certified: this.site.certified ? this.site.certified : 0,
      }
      if (this.site.id) {
        Axios("patch", "api/sites/" + this.site.id, site)
          .then((response) => {
            this.$notification.success({
              message: "Sauvegarde terminée",
              description: "Les informations d'identification ont bien été sauvegardées.",
            })
            this.$emit("update", response.data)
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: "Erreur serveur",
              description: "Une erreur server est survenue, veuillez contacter un administrateur.",
            })
          })
      } else {
        const bean = fieldsValue.fluide === "GAZ" ? "siteGazes" : "siteElecs"
        site.multiSite = true
        Axios("post", "api/" + bean, site)
          .then((response) => {
            this.$notification.success({
              message: "Sauvegarde terminée",
              description: "Les informations d'identification ont bien été sauvegardées.",
            })
            this.$emit("update", response.data)
          })
          .catch((error) => {
            if (error.response.status === 409) {
              //On récupère tous les sites de la base pour trouver si l'un d'entre eux possède le rae qu'on veut
              //Puis s'il existe, on traite ce site de sorte à rendre le rae dispo pour le transféré vers le nouveau site
              Axios("get", "api/sites/search/findByRaeEquals?rae=" + site.rae)
                .then((response) => {
                  if (response.data) {
                    let s = response.data
                    //si ope désactivée
                    Axios("get", `api/operations/search/findByIdEquals?id=${s.operationId}`).then((response) => {
                      var myOpe = response.data
                      if (myOpe.active === false) {
                        let oldSite = s
                        site.removed = 0
                        site.certified = 0
                        site.groupement = null
                        Axios("patch", "api/sites/" + oldSite.id, site).then((response) => {
                          this.$notification.success({
                            message: "Succès",
                            description: "Le site possédant ce rae s'est vu mettre à jour et transférer vers ce membre",
                          })
                          this.$emit("update", response.data)
                        })
                      } else if (myOpe.active === true && (s.status === "REJETE" || s.status === "RESILIATION")) {
                        let oldSite = s
                        //Envoi au mail contact de l'opération
                        Axios(
                          "post",
                          "/mail/transfert?idOperation=" +
                          this.operation.id +
                          "&idOldOperation=" +
                          myOpe.id +
                          "&idMembre=" +
                          this.membre.id +
                          "&membreNom=" +
                          oldSite.membreNom +
                          "&raeSite=" +
                          site.rae,
                          {
                            membreId: this.membre ? this.membre.id : null,
                            mail: this.operation.contactMail,
                            subject: "Transfert de RAE",
                            message:
                              "Transfert de RAE du membre " +
                              oldSite.membreNom +
                              " de l'opération " +
                              myOpe.id +
                              " vers le membre " +
                              site.membre +
                              " de l'opération " +
                              this.operation.id,
                            clientTechInfos: getClientTechInfos(),
                          }
                        )
                        site.removed = 0
                        site.certified = 0
                        site.groupement = null
                        Axios("patch", "api/sites/" + oldSite.id, site).then((response) => {
                          this.$notification.success({
                            message: "Succès",
                            description:
                              "Le site possédant ce rae s'est vu mettre à jour et transférer vers ce membre, un mail de suivi a été envoyé à votre administrateur. ",
                          })
                          this.$emit("update", response.data)
                        }).catch()

                      } else {
                        this.$notification.error({
                          message: "Erreur",
                          description: "Ce site est déjà affecté à une autre entité - Veuillez vous adresser à vos interlocuteurs ENGIE pour le transférer dans votre périmètre.",
                        })
                      }
                    })
                  }
                })
                .catch((error) => {
                  console.log(error)
                })
            } else {
              this.$notification.error({
                message: "Erreur serveur",
                description: "Une erreur server est survenue, veuillez contacter un administrateur.",
              })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss"></style>
