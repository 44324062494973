<template>
  <a-form :form="form" v-if="form.getFieldsValue()">
    <mcma-card
      title="Interlocuteur du périmètre de facturation"
      icon="administration"
      class="mcma-card-in-tab"
      :inline="true"
    >
      <template #describe>
        Sélectionner un interlocuteur en charge du traitement/suivi de la facture.
        <br />Si l'interlocuteur souhaité n'apparaît pas dans la liste, veuillez vérifier qu’il possède toutes les
        informations requises (prénom, nom, email, téléphone) en cliquant sur le bouton « Vérifier / modifier mes
        Interlocuteurs ». Si vous ne trouvez toujours pas : - Cas 1 : il existe déjà avec un autre rôle : utilisez la
        fonction Dupliquer après avoir cliqué sur le bouton « Vérifier / modifier mes Interlocuteurs » - Cas 2 : il
        n’existe pas encore : cliquez sur le bouton « Ajouter un nouvel interlocuteur »
      </template>
      <template #headerRightSide>
        <mcma-button
          name="Vérifier / modifier mes Interlocuteurs"
          color="primary"
          leftIcon="check-circle"
          :isFaIcon="true"
          @click="goTo('interlocuteurs')"
        />
      </template>
      <a-row type="flex" :gutter="12">
        <mcma-field
          :colSpan="16"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.interlocuteurPayeurId"
          fieldName="selectedInterlocuteurId"
          disableValue="Sélectionner un interlocuteur"
          @change="selectedInterlocuteurIdChange"
          :required="true"
          label="Interlocuteurs"
        >
          <a-select-option v-for="(interlocuteur, index) in interlocuteurs" :key="index" :value="interlocuteur.id">{{
            interlocuteur.friendly
          }}</a-select-option>
        </mcma-field>
        <a-col :span="8" class="align-column-end">
          <mcma-button
            class="grow-2"
            name="Ajouter un nouvel interlocuteur"
            color="primary"
            leftIcon="user-plus"
            :isFaIcon="true"
            @click="setInterlocuteurCreateModal(true)"
          />
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="12">
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.interlocuteurPayeurNom"
          fieldName="interlocuteurPayeurNom"
          label="Interlocuteur payeur (nom)"
          :required="true"
          :requiredMessage="`Merci de renseigner l'interlocuteur payeur (nom)`"
          :disabled="true"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.interlocuteurPayeurPrenom"
          fieldName="interlocuteurPayeurPrenom"
          label="Interlocuteur payeur (prénom)"
          :required="true"
          :requiredMessage="`Merci de renseigner l'interlocuteur payeur (prénom)`"
          :disabled="true"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.emailPayeur"
          fieldName="emailPayeur"
          label="Email"
          :required="true"
          :requiredMessage="`Merci de renseigner l'email`"
          :disabled="true"
          rule="isEmail"
        />
        <mcma-field
          :colSpan="6"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.telephonePayeur"
          fieldName="telephonePayeur"
          label="Téléphone fixe"
          :extraValidationFields="['mobilePayeur']"
          :required="!form.getFieldValue('mobilePayeur') || form.getFieldValue('mobilePayeur').length <= 0"
          :disabled="true"
          rule="isPhone"
        />
        <mcma-field
          :colSpan="6"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.mobilePayeur"
          fieldName="mobilePayeur"
          label="Téléphone mobile"
          :extraValidationFields="['telephonePayeur']"
          :requiredMessage="`ou le téléphone mobile`"
          :required="!form.getFieldValue('telephonePayeur') || form.getFieldValue('telephonePayeur').length <= 0"
          :disabled="true"
          rule="isPhone"
        />
      </a-row>
    </mcma-card>
    <!-- CHORUS BLOC SIRET -->
    <mcma-card title="Information Paiement" icon="administration" class="mcma-card-in-tab" :inline="true">
      <a-row type="flex" :gutter="12">
        <mcma-field
          type="input"
          @isOk="siretIsValid('siret is Ok', form.getFieldValue('siret'))"
          :form="form"
          :wasSubmit="wasSubmit"
          :extraLabel="'votre siren est : ' + defaultValueSiren"
          fieldName="siret"
          label="Recherche de votre structure chorus destinataire des factures via Siren ou Siret"
          :required="true"
          rule="isSiren"
          :defaultValue="groupement.siret"
          @change="siretChange('siret change', form.getFieldValue('siret'))"
        />
      </a-row>
      <a-row type="flex" :gutter="12">
        <span v-if="debug">
          <pre>isSiretChorus : {{ isSiretChorus }} </pre>
          <pre>listChorusStructureLoaded : {{ listChorusStructureLoaded }} </pre>
          <pre>groupement.chorusConfirmation : {{ groupement.chorusConfirmation }} </pre>
          <pre>chorusConfirmationCheck : {{ chorusConfirmationCheck }} </pre>
        </span>

        <span v-if="isSiretChorus != null && !isSiretChorus">
          Aucune structure chorus active n'a été trouvée à partir de ce numéro siren, par avance merci :
          <br />- de vérfier qu'il s'agit bien du numéro siren du contractant, <br />- ou bien de nous confirmer si vous
          souhaitez être facturé via Chorus ou pas ? <br />Dans l'affirmatif, Vous allez renseigner une valeur non
          recevable actuellement dans l’annuaire Chorus. <br /><strong
            >En confirmant votre choix Chorus, vous vous engagez à faire le nécessaire pour que ces données soient
            intégrées dans l’annuaire Chorus d’ici la prise d’effet du marché, au plus tard.</strong
          >
        </span>
        <mcma-field
          v-if="isSiretChorus != null && !isSiretChorus"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="(groupement.chorusConfirmation && chorusConfirmationCheck === null) || chorusConfirmationCheck"
          fieldName="chorusConfirmation"
          label="Confirmation Chorus"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::chorusConfirmation"
          :required="true"
          @change="updateChorusConfirmation()"
        />
        <mcma-field
          v-if="chorusConfirmationCheck"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.dispoEdi"
          fieldName="dispoEdi"
          label="Est-il prévu un dispositif EDI en aval de Chorus ?"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::dispoEdi"
          :required="true"
        />
      </a-row>
      <!-- CHORUS BLOC STRUCTURE -->
      <a-row type="flex" :gutter="12" v-if="(isSiretChorus && listChorusStructureLoaded) || chorusConfirmationCheck">
        <mcma-field
          v-if="!chorusConfirmationCheck"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="chorusNomStructureExecutant"
          :defaultValue="groupement.chorusNomStructureExecutant"
          disableValue="Sélectionnez la structure chorus"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::chorusNomStructureExecutant"
          :required="true"
          :defaultList="listChorusStructureActive.map((e) => e.raisonSociale + ' ' + e.identifiant)"
          @change="updateStructureChorus('Change', form.getFieldsValue())"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.codeSiretExecutant || groupement.siret"
          :disabled="!chorusConfirmationCheck"
          fieldName="codeSiretExecutant"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::codeSiretExecutant"
          :required="!chorusConfirmationCheck"
          rule="isSiret"
          @change="updateSiretChorus(form.getFieldsValue())"
        />
      </a-row>
      <!-- CHORUS BLOC SERVICE -->
      <a-row type="flex" :gutter="12" v-if="(isSiretChorus && listChorusServiceLoaded) || chorusConfirmationCheck">
        <mcma-field
          v-if="!chorusConfirmationCheck"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="chorusNomServiceExecutant"
          disableValue="Sélectionnez le service chorus"
          :defaultValue="groupement.chorusNomServiceExecutant"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::chorusNomServiceExecutant"
          :required="chorusCodeServiceExecutantIsRequired"
          :defaultList="listChorusServiceActif.map((e) => e.nom + ' ' + e.code)"
          :extraLabel="msgCodeServiceExecutant"
          @change="updateServiceChorus('Change', form.getFieldsValue())"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.chorusCodeServiceExecutant || groupement.codeServiceExecutant"
          fieldName="chorusCodeServiceExecutant"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::chorusCodeServiceExecutant"
          :disabled="listChorusServiceLoaded && !chorusConfirmationCheck"
          :required="chorusCodeServiceExecutantIsRequired === null ? false : chorusCodeServiceExecutantIsRequired"
        />
      </a-row>
      <!-- CHORUS BLOC ENGAGEMENT -->
      <a-row type="flex" :gutter="12" v-if="(isSiretChorus && listChorusServiceLoaded) || chorusConfirmationCheck">
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.chorusCodeEngagementJuridique || groupement.engagementJuridique"
          :disabled="false"
          fieldName="chorusCodeEngagementJuridique"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::chorusCodeEngagementJuridique"
          :required="chorusCodeEngagementJuridiqueIsRequired === null ? false : chorusCodeEngagementJuridiqueIsRequired"
        />
        <span v-if="chorusConfirmationCheck"
          >Dans le cas où vous souhaitre utiliser un code Service et/ou un code d'Engagement Juridique pour ce périmètre
          de facturation mais que leurs valeurs ne sont pas encore connues à cette date, merci de renseignez ici une
          valeur provisoire non ambigüe.
          <br />
          Vous remplacerez ensuite tout simplement par la vraie valeur dans votre Espace Client Bill-E. Jusqu’à cette
          mise à jour, vous vous engagez à accepter les factures émises par ENGIE avec les code provisoire.</span
        >
        <span v-if="!chorusConfirmationCheck"
          >Dans le cas où vous souhaitre utiliser un code d'Engagement Juridique pour ce périmètre de facturation mais
          que sa valeur n'est pas encore connue à cette date, merci de renseignez ici une valeur provisoire non ambigüe.
          <br />
          Vous remplacerez ensuite tout simplement par la vraie valeur dans votre Espace Client Bill-E. Jusqu’à cette
          mise à jour, vous vous engagez à accepter les factures émises par ENGIE avec les code provisoire.</span
        >
      </a-row>
      <!-- Ref Contrat et Marché -->
      <a-row type="flex" :gutter="24">
        <mcma-field
          :colSpan="12"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.refContrat"
          :disabled="false"
          fieldName="refContrat"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::refContrat"
          :required="false"
        />
        <mcma-field
          :colSpan="12"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.refMarche"
          :disabled="false"
          fieldName="refMarche"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::refMarche"
          :required="false"
        />
      </a-row>

      <!-- CHORUS BLOC DEBUG -->
      <a-row v-if="debug">
        <br />
        -- SIREN
        <br />
        <span>siret : {{ form.getFieldValue("siret") }} </span>
        <br />
        <span>isSiretChorus ? : {{ isSiretChorus }}</span>
        <br />
        <span>chorusConfirmationCheck : {{ chorusConfirmationCheck }}</span>
        <br />
        -- Structure Chorus
        <br />
        <span
          >listChorusStructureinBdd liste remplie ? :
          {{ listChorusStructureinBdd !== null }}
        </span>
        <br />
        <span>codeSiretExecutant : {{ form.getFieldValue("codeSiretExecutant") }} </span>
        <br />
        -- Service Chorus
        <br />
        <span
          >listChorusServiceInBdd liste remplie ? :
          {{ listChorusServiceInBdd !== null }}
        </span>
        <br />
        <span
          >listChorusServiceInBdd Liste ? :
          {{ listChorusServiceInBdd !== null ? listChorusServiceInBdd : "vide" }}
        </span>
        <br />
        <span>chorusCodeServiceExecutantIsDisable : {{ listChorusServiceInBdd !== null }}</span>
        <br />
        <span>chorusCodeServiceExecutantIsRequired : {{ chorusCodeServiceExecutantIsRequired }}</span>
        <br />
        -- EngagementJuridique Chorus
        <br />
        <span>groupement.chorusCodeEngagementJuridique : {{ groupement.chorusCodeEngagementJuridique }}</span>
        <br />
        <span>chorusCodeEngagementJuridiqueIsDisable : {{ false }}</span>
        <br />
        <span>chorusCodeEngagementJuridiqueIsRequired : {{ chorusCodeEngagementJuridiqueIsRequired }}</span>
      </a-row>
      <template #describe>
        Si votre entité est assujettie à Chorus Pro, vous allez pouvoir définir ci-dessous les paramètres Chorus
        affectés au périmètre de facturation en cours. Pour ce faire, il vous suffit de renseigner ci-dessous le « SIREN
        destinataire de facture ». Vous pourrez alors choisir parmi les données enregistrées sur Chorus Pro pour ce
        SIREN (SIRET Chorus, code Service le cas échéant, code EJ le cas échéant). <br />
      </template>
    </mcma-card>
    <mcma-card
      title="Option de transmission des factures"
      icon="administration"
      class="mcma-card-in-tab"
      :inline="true"
      v-if="!isSiretChorus && !chorusConfirmationCheck"
    >
      <a-row type="flex" :gutter="12" class="switch-group">
        <mcma-field
          :colSpan="24"
          v-if="!chorusConfirmationCheck"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.id ? groupement.papier : false"
          fieldName="papier"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::papier"
          @change="factureSelectChange()"
        />
        <mcma-field
          :colSpan="24"
          v-if="!chorusConfirmationCheck"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.id ? groupement.factureElectr : false"
          fieldName="factureElectr"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::factureElectr"
          @change="factureSelectChange()"
        />
        <mcma-field
          :colSpan="24"
          v-if="!chorusConfirmationCheck && (groupement.factureElectr || form.getFieldValue('factureElectr'))"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.id ? groupement.emailEnvoi : null"
          fieldName="emailEnvoi"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::emailEnvoi"
          rule="isEmail"
        />
        <!--
        <mcma-field
          :colSpan="24"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.optionExcel"
          fieldName="optionExcel"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::optionExcel"
        />
        -->
        <mcma-field
          :colSpan="24"
          v-if="!chorusConfirmationCheck"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.id ? groupement.dematXml : false"
          fieldName="dematXml"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::dematXml"
          @change="factureSelectChange()"
        />
      </a-row>
    </mcma-card>
    <mcma-card
      title="Service dat@facture"
      icon="administration"
      class="mcma-card-in-tab"
      :inline="true"
    >
    <a-row type="flex" :gutter="12" class="switch-group">
        <mcma-field
          :colSpan="24"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.dataFacture"
          fieldName="dataFacture"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::dataFacture"
        />
    </a-row>
    </mcma-card>
    <a-row type="flex" :gutter="12" class="switch-group valid">
      <mcma-field
        class="validChorus"
        :colSpan="24"
        v-if="chorusConfirmationCheck || form.getFieldValue('siret')"
        type="switch"
        :form="form"
        :defaultValue="false"
        :wasSubmit="wasSubmit"
        fieldName="validChorus"
        getFlow="name::groupements"
        getPanel="name::groupements"
        getField="type::validChorus"
        :label="validChorusLabel"
      />
    </a-row>
    <a-row type="flex" :gutter="12">
      <a-col :span="24">
        <div class="button-group-inline mt24">
          <mcma-button
            name="Réinitialiser"
            color="primary"
            leftIcon="undo-alt"
            :isFaIcon="true"
            @click="initialize(true)"
          />
          <mcma-button
            :disabled="!form.getFieldValue('validChorus')"
            :name="getSaveButtonTitle"
            class="ml12"
            color="success"
            leftIcon="save"
            :isFaIcon="true"
            @click="submit"
          />
        </div>
      </a-col>
    </a-row>
    <interlocuteur-create-modal
      :visible="showInterlocuteurCreateModal"
      from="groupement"
      @onCancel="setInterlocuteurCreateModal"
      @onOk="pushNewInterlocuteurFromModal"
    />
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import _ from "lodash"
import { pick } from "@/util/Helpers"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import InterlocuteurCreateModal from "@/components/mcma/steps/interlocuteurs/InterlocuteurCreateModal"
import { mapState } from "vuex"

export default {
  name: "GroupementsInterlocuteurForm",
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    InterlocuteurCreateModal,
  },
  props: {
    groupement: Object,
  },
  data() {
    return {
      validChorusLabel:
        "Je confirme la recevabilité des données Chorus spécifiées pour mon entité. Je m’engage à accepter et à régler les factures à venir porteuses de ces données Chorus (sous réserve de leur conformité contractuelle) tant qu’aucune demande de modification n’aura pas été adressée à ENGIE ",
      form: this.$form.createForm(this),
      wasSubmit: false,
      chorusConfirmationCheck: null, // Déroguation Chorus si true on passe en saisie libre

      // Variable dédié au Siren
      isSiretChorus: null, // True dans le cas où le siren à été trouvé sur la base de donénes Chorus

      // Variable dédié au Structure Chorus
      listChorusStructureinBdd: null, // Contient la liste des Structures Chorus trouvée en Base pour le n° de SIREN
      listChorusStructureActive: null, // Contient la liste des Structures Chorus trouvée en Base pour le n° de SIREN
      listChorusStructureLoaded: false, // tag durant le chargement de la liste des Structure Chorus
      structureChorusEnc: null, // Contient la structure choisie par l'utilisateur

      // Variable dédié au Service Chorus
      listChorusServiceInBdd: null, // Contient la liste des Service Chorus en Base pour le n° de SIREN
      listChorusServiceActif: null, // Contient la liste des Service Chorus Actif
      listChorusServiceLoaded: false, // tag durant le chargement de la liste des Sercices Chorus
      serviceChorusEnc: null, // Contient le service choisi par l'utilisateur

      // Variable dédié à l'état de gestion Chorus
      gestionEgmt: null, // Est égal chorusService.gestionEgmt du Service Chorus selectionné par l'utilisateur
      chorusCodeServiceExecutantIsRequired: null, // True si un code Service Chorus (SE) est oblogatoire
      chorusCodeEngagementJuridiqueIsRequired: null, // True si une code Engagement Juridique Chorus (EJ)) est obligatoire
      checkCodeEngagementJuridiqueIsRequiredCheded: false, // False tant que le statut n'a pas été checked

      //
      getMsgTypeFacturation: "",
      msgCodeServiceExecutant: null,

      // Variable dédié au Interlocuteur
      interlocuteurs: [],
      originalSelectedInterlocuteur: {},
      showInterlocuteurCreateModal: false,
    }
  },
  computed: {
    ...mapState(["membre", "operation", "debug"]),
    getCapitalize() {
      return (value) => {
        return this.$options.filters.capitalize(value)
      }
    },
    defaultValueSiren() {
      console.log("this.groupement :%O", this.groupement)
      console.log("this.membre :%O", this.membre)

      if (this.groupement && this.groupement.codeSiretExecutant) {
        if (this.groupement.codeSiretExecutant > 999999999) {
          return Math.trunc(this.groupement.codeSiretExecutant / 100000)
        }
        return this.groupement.codeSiretExecutant
      }

      if (this.groupement && this.groupement.siretPayeur) {
        if (this.groupement.siretPayeur > 999999999) {
          return Math.trunc(this.groupement.siretPayeur / 100000)
        }
        return this.groupement.siretPayeur
      }

      if (this.membre && this.membre.siret) {
        if (this.membre.siret > 999999999) {
          return Math.trunc(this.membre.siret / 100000)
        }
        return this.membre.siret
      }
      return null
    },
    getSaveButtonTitle() {
      if (this.$route.params.action === "duplique" || this.groupement.id) {
        return `Valider les informations`
      }
      return "Créer le périmètre de facturation"
    },
  },
  mounted() {
    this.initialize()
  },
  watch: {
    structureChorusEnc: function (val) {
      console.log("WATCH structureChorusEnc : Nouvelle valeur pour la structureChorusEnc : %O", val)
      // if(this.chorusConfirmationCheck&&this.listChorusStructureLoaded) {
      if (this.listChorusStructureLoaded && this.listChorusServiceLoaded) {
        this.checkCodeServiceExecutantIsRequired("watcher_structureChorusEnc")
      }
    },
    serviceChorusEnc: function (val) {
      console.log("WATCH serviceChorusEnc : Nouvelle valeur pour la serviceChorusEnc : %O", val)
      // if(this.chorusConfirmationCheck&&this.listChorusStructureLoaded&&this.structureChorusEnc&&this.listChorusServiceLoaded&&this.serviceChorusEnc) {
      if (this.listChorusStructureLoaded && this.listChorusServiceLoaded) {
        this.checkCodeEngagementJuridiqueIsRequired("watcher_serviceChorusEnc")
      }
    },
  },
  methods: {
    factureSelectChange() {
      this.form.setFieldsValue({
        factureElectr: false,
        papier: false,
        dematXml: false,
      })
    },
    setInterlocuteurCreateModal(show) {
      this.showInterlocuteurCreateModal = !!show
    },
    pushNewInterlocuteurFromModal(interlocuteur) {
      this.pushInterlocuteur(interlocuteur)
      this.originalSelectedInterlocuteur = this.interlocuteurs.find((inter) => inter.id === interlocuteur.id)
      this.setInterlocuteurPayeurSelected(this.originalSelectedInterlocuteur)
      this.setInterlocuteurPayeurSelected({
        selectedInterlocuteurId: this.originalSelectedInterlocuteur.id,
      })
      this.setInterlocuteurCreateModal()
    },
    // -- ---------------------------------
    // Init
    async initialize(reset) {
      console.group("> initialize")
      console.log("> initialize : %O", reset)
      this.form.getFieldDecorator("nom")
      this.form.getFieldDecorator("budget")
      this.form.getFieldDecorator("raisonSocialPayeur")
      this.form.getFieldDecorator("adresseFacturation")
      this.form.getFieldDecorator("codePostal")
      this.form.getFieldDecorator("commune")
      this.form.getFieldDecorator("interlocuteurPayeurNom")
      this.form.getFieldDecorator("interlocuteurPayeurId")
      this.form.getFieldDecorator("interlocuteurPayeurPrenom")
      this.form.getFieldDecorator("emailPayeur")
      this.form.getFieldDecorator("telephonePayeur")
      this.form.getFieldDecorator("mobilePayeur")
      this.form.getFieldDecorator("siret")
      this.form.getFieldDecorator("iban")
      this.form.getFieldDecorator("moyenPaiement")
      this.form.getFieldDecorator("delaiPaiement")
      this.form.getFieldDecorator("codeIdentificationIndividuelle")
      this.form.getFieldDecorator("numConventionTripartite")
      this.form.getFieldDecorator("engagementJuridique")
      this.form.getFieldDecorator("codeServiceExecutant")
      this.form.getFieldDecorator("rythmeFacturation")
      this.form.getFieldDecorator("optionExcel")
      this.form.getFieldDecorator("factureElectr")
      this.form.getFieldDecorator("emailEnvoi")
      this.form.getFieldDecorator("dematXml")
      this.form.getFieldDecorator("papier")
      this.form.getFieldDecorator("sepaCheck")
      this.form.getFieldDecorator("sepaFile")
      this.form.getFieldDecorator("codeSiretExecutant")
      this.form.getFieldDecorator("chorusConfirmation")
      this.form.getFieldDecorator("chorusCodeServiceExecutant")
      this.form.getFieldDecorator("chorusCodeEngagementJuridique")
      this.form.getFieldDecorator("chorusNomServiceExecutant")
      this.form.getFieldDecorator("chorusNomStructureExecutant")
      this.form.getFieldDecorator("dispoEdi")
      this.form.getFieldDecorator("refContrat")
      this.form.getFieldDecorator("refMarche")
      this.form.getFieldDecorator("validChorus")
      this.form.getFieldDecorator("dataFacture")

      this.interlocuteurs = []
      this.originalSelectedInterlocuteur = {}

      try {
        await this.prepareInterlocuteurs()
        const siteInterlocuteur = await this.prepareInterlocuteurBySite()
        const membreInterlocuteur = await this.prepareInterlocuteurByMembre()
        this.originalSelectedInterlocuteur = siteInterlocuteur || membreInterlocuteur || {}
      } catch (e) {
        console.error(e)
      }

      if (this.originalSelectedInterlocuteur && this.originalSelectedInterlocuteur.id) {
        this.setInterlocuteurPayeurSelected(this.originalSelectedInterlocuteur)
        this.setInterlocuteurPayeurSelected({
          selectedInterlocuteurId: this.originalSelectedInterlocuteur.id,
        })
      } else {
        this.resetForm()
      }
      if (reset) {
        this.wasSubmit = false
        this.initializeFields()
        this.$emit("reset")
      }
      // Init Chorus
      this.chorusConfirmationCheck = this.groupement.chorusConfirmation
      if (this.groupement && this.groupement.siret) this.siretIsValid("initialize", this.groupement.siret)
      console.groupEnd()
    },
    initializeFields() {
      console.log("> initializeFields")
      let setFields = pick(this.groupement, [
        "nom",
        "budget",
        "raisonSocialPayeur",
        "adresseFacturation",
        "codePostal",
        "commune",
        "interlocuteurPayeurNom",
        "interlocuteurPayeurPrenom",
        "emailPayeur",
        "telephonePayeur",
        "mobilePayeur",
        "siret",
        "iban",
        "moyenPaiement",
        "delaiPaiement",
        "codeIdentificationIndividuelle",
        "numConventionTripartite",
        "engagementJuridique",
        "codeServiceExecutant",
        "rythmeFacturation",
        "optionExcel",
        "dematXml",
        "factureElectr",
        "emailEnvoi",
        "papier",
        "sepaCheck",
        "chorusConfirmation",
        "chorusCodeServiceExecutant",
        "chorusCodeEngagementJuridique",
        "chorusNomStructureExecutant",
        "chorusNomServiceExecutant",
        "dispoEdi",
        "refContrat",
        "refMarche",
        "validChorus",
        "dataFacture",
      ])
      this.$nextTick(() => {
        const fields = this.form.getFieldsValue()
        fields.papier = this.groupement.id ? this.groupement.papier : true
        fields.sepaFile = null
        setFields = this.$mergeObject(fields, setFields)
        this.form.setFieldsValue(setFields)
      })
    },
    onSepaUpload(check, file) {
      this.form.setFieldsValue({
        sepaCheck: check,
        sepaFile: file,
      })
    },
    // ----------------------------------------------
    // Mecanique Chorus
    // Check Required
    checkCodeServiceExecutantIsRequired(declencher) {
      console.group("checkCodeServiceExecutantIsRequired")
      console.log("declencher By : %O", declencher)
      console.log("siret : %O", this.siret)
      console.log("this.listChorusStructureLoaded :%O", this.listChorusStructureLoaded)
      console.log("this.structureChorusEnc :%O", this.structureChorusEnc)
      console.log("this.structureChorusEnc.gestionService :%O", this.structureChorusEnc.gestionService)
      console.log(
        "this.structureChorusEnc.gestionServiceEngagement :%O",
        this.structureChorusEnc.gestionServiceEngagement
      )

      let res = true
      if (this.chorusConfirmationCheck) {
        console.log("active regle 1 : non requis car mode chorus Confirmation actif")
        res = false
      } else {
        if (this.listChorusStructureLoaded && this.structureChorusEnc) {
          console.log("active regle 2 : car la structureChorusEnc est chargée")
          res = this.structureChorusEnc.gestionService || this.structureChorusEnc.gestionServiceEngagement
        } else {
          console.warn("active regle 2 échapée : car structureChorusEnc non chargée")
        }
      }
      console.log("res : %O", res)
      console.groupEnd()
      this.chorusCodeServiceExecutantIsRequired = res
      return res
    },
    checkCodeEngagementJuridiqueIsRequired(declencher) {
      // this.checkCodeEngagementJuridiqueIsRequiredCheded = false
      console.group("checkCodeEngagementJuridiqueIsRequired")
      console.log("declencher : %O" + declencher)
      let siret = this.form.getFieldValue("siret")
      console.log("siret : %O", siret)
      console.log("this.structureChorusEnc : %O", this.structureChorusEnc)
      console.log("this.serviceChorusEnc.gestionEngagement : %O", this.structureChorusEnc.gestionEngagement)
      console.log("this.serviceChorusEnc : %O", this.serviceChorusEnc)
      let res = true
      if (!siret) {
        console.log("active regle 0 : pas de numéro siret... (vérifier si l'on conserve)")
        res = false
      } else {
        if (this.chorusConfirmationCheck) {
          console.log("active regle 1 : mode de saisie libre Chorus")
          res = false
        } else {
          if (
            this.listChorusStructureLoaded &&
            this.structureChorusEnc &&
            this.listChorusServiceLoaded &&
            this.serviceChorusEnc
          ) {
            console.log("active regle 2 : structureChorusEnc & serviceChorusEnc chargée")
            const regex = /^_SANS_OBJET_.*$/
            console.log("this.structureChorusEnc.gestionEngagement : %O", this.structureChorusEnc.gestionEngagement)
            console.log(
              "this.structureChorusEnc.gestionServiceEngagement : %O",
              this.structureChorusEnc.gestionServiceEngagement
            )
            console.log("this.serviceChorusEnc.gestionEgmt : %O", this.serviceChorusEnc.gestionEgmt)
            console.log("this.serviceChorusEnc.code : %O", this.serviceChorusEnc.code)
            console.log("this.serviceChorusEnc.code.match(regex) : %O", this.serviceChorusEnc.code.match(regex))
            console.log(
              "this.serviceChorusEnc.code.match(regex)===null : %O",
              this.serviceChorusEnc.code.match(regex) === null
            )
            // Si gestion gestionServiceEngagement = vrai & un service autre qu'un SANS_OBJET alors pas besoin
            // Si gestion gestionServiceEngagement = faux alors pas besoin

            res =
              this.structureChorusEnc.gestionEngagement ||
              this.serviceChorusEnc.gestionEgmt ||
              (this.structureChorusEnc.gestionServiceEngagement && !this.serviceChorusEnc.code.match(regex) === null)
            // BAD... car c'est le fait de selectionner un service qui compte
            // res =  this.serviceChorusEnc.gestionEngagement || this.serviceChorusEnc.gestionEgmt || !(this.structureChorusEnc.gestionServiceEngagement && this.serviceChorusEnc.gestionService)
          } else {
            console.warn("active regle 2 échapée : car structureChorusEnc ou serviceChorusEnc non chargés")
          }
        }
      }
      console.log("res : %O", res)
      console.groupEnd()
      this.chorusCodeEngagementJuridiqueIsRequired = res
      this.checkCodeEngagementJuridiqueIsRequiredCheded = true
      console.log("this.form :%O", this.form)
      return res
    },
    // Update des champs
    updateChorusConfirmation() {
      this.chorusConfirmationCheck = !this.form.getFieldValue("chorusConfirmation")
      console.log("> this.chorusConfirmationCheck : %O", this.chorusConfirmationCheck)
      // this.isSiretChorus = null
      // this.groupement.chorusConfirmation = this.chorusConfirmationCheck
      this.listChorusServiceInBdd = []
      this.listChorusServiceActif = []
      this.listChorusStructureinBdd = []
      this.listChorusStructureActive = []
      this.structureChorusEnc = null
      this.serviceChorusEnc = null
      this.form.setFieldsValue({
        engagementJuridique: null,
        codeSiretExecutant: null,
        chorusNomStructureExecutant: null,
        chorusNomServiceExecutant: null,
        chorusCodeServiceExecutant: null,
        chorusCodeEngagementJuridique: null,
      })
      this.$nextTick(() => {
        this.form.setFieldsValue({
          engagementJuridique: null,
          codeSiretExecutant: null,
          chorusNomStructureExecutant: null,
          chorusCodeServiceExecutant: null,
          chorusNomServiceExecutant: null,
          chorusCodeEngagementJuridique: null,
        })
      })
    },
    updateSiret(groupement) {
      console.group("updateSiret@GroupementsIterlocuteurForm")
      console.log("> value : %O", groupement.siret)
      this.siretIsValid("updateSiret", groupement.siret)
      console.groupEnd()
    },
    updateSiretChorus(groupement) {
      console.group("updateServicesChorus@GroupementsIterlocuteurForm")
      console.log("> value : %O", groupement.codeSiretExecutant)
      if (!this.chorusConfirmationCheck) this.siretChorusIsValid("updateSiretChorus", groupement.codeSiretExecutant)
      console.groupEnd()
    },
    // Update des champs & Data Chorus
    updateStructureChorus(declencher, value) {
      console.log("valuuuuuuuuue>>>>>>>>>>>>>",value)
      console.group("updateStructureChorus@GroupementsIterlocuteurForm")
      console.log("> declencher value : %O %O", declencher, value)
      this.listChorusServiceInBdd = []
      this.listChorusServiceActif = []
      if (declencher !== "initialize_siretIsValid") {
        this.form.setFieldsValue({
          engagementJuridique: null,
          codeServiceExecutant: null,
          chorusNomServiceExecutant: null,
          chorusCodeServiceExecutant: null,
          chorusCodeEngagementJuridique: null,
        })
      }
      // ON cherche la liste des structure Chorus
      if (!this.chorusConfirmationCheck) {
        // Si pas en mode Chorus libre
        console.log("this.listChorusStructureActive : %O", this.listChorusStructureActive)
        console.log("Valeur recherchée : %O", value)
        let reshearch = value.raisonSociale || value.chorusNomStructureExecutant
        //Dans le cas d'un nom identique dans la selection,  => on récup le siret pour selectionner, dans les autres cas (update auto), on a la raison social préci
        if(reshearch != value.raisonSociale){
          reshearch = reshearch.split(' ')
          //on recup la derniere partie du tableau split -> SIRET fournit dans le champ structure dû aux possible multiple key pour le nom
          reshearch = reshearch[reshearch.length - 1]
        }
        console.log("Valeur recherchée : %O", reshearch)

        if (this.listChorusStructureActive) {
          for (let key in this.listChorusStructureActive) {
            console.log(
              "> listChorusStructureinBdd[key].raisonSociale : %O %O",
              key,
              this.listChorusStructureActive[key].raisonSociale
            )
            if (this.listChorusStructureActive[key].raisonSociale === reshearch || this.listChorusStructureActive[key].identifiant === reshearch) {
              console.log("> found it : %O %O", key, this.listChorusStructureActive[key].raisonSociale)
              console.log("> identifiant : %O %O", this.listChorusStructureActive[key].identifiant)
              if (declencher !== "initialize_siretIsValid")
                this.form.setFieldsValue({
                  codeSiretExecutant: this.listChorusStructureActive[key].identifiant,
                  //On remet le champ nomStructure à la valeur raison sociale, car sinon on affecte le siret avec (fix de l'error onChange avec plusieurs raison social ==)
                  chorusNomStructureExecutant: this.listChorusStructureActive[key].raisonSociale,
                })
              this.structureChorusEnc = this.listChorusStructureActive[key]
              this.siretChorusIsValid(
                declencher + "_updateStructreChorus",
                this.listChorusStructureActive[key].identifiant
              ) // active le chargement des services
              console.log("this.structureChorusEnc : %O", this.structureChorusEnc)
              break
            }
          }
        } else {
          console.warn("Update declencher mais la liste des Structure Active est vide...")
        }
      }
      console.groupEnd()
    },
    updateServiceChorus(declencher, value) {
      console.group("updateServiceChorus@GroupementsIterlocuteurForm")
      console.log("> value : %O %O", declencher, value)
      if (declencher === "initialize_siretIsValid_updateStructreChorus_siretChorusIsValid") {
        this.form.setFieldsValue({
          engagementJuridique: null,
          codeServiceExecutant: null,
          chorusCodeServiceExecutant: null,
          chorusCodeEngagementJuridique: null,
        })
      }

      if (!this.chorusConfirmationCheck) {
        // Si pas en mode Chorus libre
        if (this.listChorusServiceActif) {
          for (let key in this.listChorusServiceActif) {
            const valueName = this.listChorusServiceActif[key].nom + ' ' + this.listChorusServiceActif[key].code
            if (valueName === value.chorusNomServiceExecutant) {
              console.log("> found it : %O %O", key, this.listChorusServiceActif[key].nom)
              console.log("> identifiant : %O %O", this.listChorusServiceActif[key].code)
              this.serviceChorusEnc = this.listChorusServiceActif[key]
              this.$nextTick(() => {
                this.form.setFieldsValue({
                  chorusCodeServiceExecutant: this.listChorusServiceActif[key].code,
                })
              })
              break
            }
          }
        }
      } else {
        console.log("Non update car en mode Chorus saisie libre")
      }
      console.groupEnd()
    },
    // Update chargement des listes selecteurs
    addChorusServiceEmpty(codeCasChorus) {
      console.group("addChorusServiceEmpty@GroupementIntelocuteur")
      console.log("codeCasChorus : %O", codeCasChorus)
      console.log("structureChorusEnc : %O", this.structureChorusEnc)
      var code = ""
      if (codeCasChorus === "_SANS_OBJET_CAR_PAS_DE_CODE_SERVICE_EN_BASE_CHORUS_")
        code = "Vous n'avez aucun Code service déclaré sur Chorus"
      if (codeCasChorus === "_SANS_OBJET_PAS_DE_SERVICE_CHORUS_ACTIF_")
        code = "Vous n'avez aucun de service déclaré Actif sur Chorus"
      if (codeCasChorus === "_SANS_OBJET_CHOIX_VOLONTAIRE_") code = "_Je ne souhaite pas utiliser de service Chorus_"
      const chorusServiceEmpty = {
        nom: code,
        code: codeCasChorus,
        serviceActif: false,
        gestionEgmt: this.structureChorusEnc.gestionServiceEngagement || this.structureChorusEnc.gestionEngagement,
      }
      this.listChorusServiceActif.push(chorusServiceEmpty)
      // On n'oublie pas du coup de forcer le satus requis de champ SE
      this.chorusCodeServiceExecutantIsRequired = true
      if (this.listChorusServiceActif.length > 1)
        this.msgCodeServiceExecutant =
          "Dans le cas où vous ne souhaitez pas activer un service Chorus selectionner : 'Je ne souhaite pas utiliser de service Chorus'"
      console.log("this.listChorusServiceInBdd : %O", this.listChorusServiceInBdd)
      console.groupEnd()
    },
    // Is valide & Change
    siretChange(declencher, value) {
      console.group("siretChange")
      console.log("siretChange declencher : %O", declencher)
      console.log("siretChange value : %O", value)
      this.isSiretChorus = null
      this.listChorusStructureinBdd = []
      this.listChorusServiceInBdd = []
      this.listChorusStructureActive = []
      this.listChorusServiceActif = []
      this.chorusConfirmation = null
      this.chorusCodeServiceExecutantIsRequired = null
      this.chorusCodeEngagementJuridiqueIsRequired = null
      this.chorusConfirmationCheck = null
      this.msgCodeServiceExecutant = ""
      this.form.setFieldsValue({
        engagementJuridique: null,
        codeServiceExecutant: null,
        chorusNomStructureExecutant: null,
        codeSiretExecutant: null,
        chorusNomServiceExecutant: null,
        chorusCodeServiceExecutant: null,
      })
      this.$nextTick(() => {
        this.form.setFieldsValue({
          engagementJuridique: null,
          codeServiceExecutant: null,
          chorusNomStructureExecutant: null,
          codeSiretExecutant: null,
          chorusNomServiceExecutant: null,
          chorusCodeServiceExecutant: null,
        })
      })
      console.groupEnd()
    },
    siretIsValid(declencher, value) {
      console.group("siretIsValid")
      console.log("siretIsValid declencher : %O", declencher)
      console.log("siretIsValid value : %O", value)
      // if (!this.chorusConfirmationCheck) {
      // Si pas en mode Chorus libre
      this.isSiretChorus = null
      if (value.length >= 9) {
        this.listChorusStructureLoaded = false
        // By BDD
        // Axios("get", "api/chorusAnnuaires/search/findByIdentifiantStartingWith?identifiant=" + value.substring(0, 9))
        // By Chorus Live
        Axios("get", "chorus/structures/" + value.substring(0, 9))
          .then((response) => {
            console.log("> response getApi chorusAnnuaire : %O", response)
            //By BDD
            // if (response.data._embedded.chorusAnnuaires && response.data._embedded.chorusAnnuaires.length > 0)
            // By Chorus Live
            if (response.data && response.data.length) {
              // By BDD
              // this.listChorusStructureinBdd = response.data._embedded.chorusAnnuaires;
              // By ChorusLive
              this.listChorusStructureinBdd = response.data

              // Hum Hum... faut pas filtré les sturctures....
              this.listChorusStructureActive = this.getListChorusStructureActive(this.listChorusStructureinBdd)
              // this.listChorusStructureActive = this.listChorusStructureinBdd
              // Bien forcer les états IHM en sortie pour assurer la cascade !!!
              // A chaque fois bien relacher this.listChorusStructureLoaded avec le set IHM !!!
              // Dans le cas où il n'y a rien on active simplement la confirmation CHORUS
              // Dans le cas où il y a une seul élément on set la valeur de la structure et l'IHM puis on fait la cascade
              console.log("this.listChorusStructureActive :%O", this.listChorusStructureActive)
              if (this.listChorusStructureActive.length === 0) {
                console.log("> Une seule structure active trouvée...")
                // Décharge tout et on passe en mode Chorus Libre
                // exemple siren 210100954
                this.listChorusStructureinBdd = []
                this.listChorusStructureActive = []
                this.isSiretChorus = false
                if (declencher !== "initialize") {
                  this.chorusConfirmationCheck = false
                  this.form.setFieldsValue({
                    engagementJuridique: null,
                    chorusNomStructureExecutant: null,
                    codeServiceExecutant: null,
                    codeSiretExecutant: null,
                    chorusNomServiceExecutant: null,
                    chorusCodeServiceExecutant: null,
                  })
                }
              } else {
                if (this.listChorusStructureActive.length === 1) {
                  // Dans le cas ou il n'y a qu'un seul élément dans la liste
                  // on force aussitôt la valeur de gestionService
                  console.log("> Une seule structure active trouvée...")
                  console.log("> this.listChorusStructureActive : %O", this.listChorusStructureActive)
                  this.listChorusStructureLoaded = true
                  this.structureChorusEnc = this.listChorusStructureActive[0]
                  this.$nextTick(() => {
                    this.form.setFieldsValue({
                      chorusNomStructureExecutant: this.listChorusStructureActive[0].raisonSociale,
                      codeSiretExecutant: this.listChorusStructureActive[0].identifiant,
                    })
                  })
                  // On force l'update
                  this.updateStructureChorus(declencher + "_siretIsValid", this.listChorusStructureActive[0])
                } else {
                  // Dans le cas ou il y a plusieurs on force l'utilisateur à choisir
                  this.chorusConfirmationCheck = false // si isSiretChorus = true => obligatoirement chorusConfirmationCheck = false
                  this.form.setFieldsValue({
                    papier: false,
                  })
                  if (declencher !== "initialize") {
                    this.$nextTick(() => {
                      this.form.setFieldsValue({
                        engagementJuridique: null,
                        chorusNomStructureExecutant: null,
                        codeServiceExecutant: null,
                        codeSiretExecutant: null,
                        chorusNomServiceExecutant: null,
                        chorusCodeServiceExecutant: null,
                      })
                    })
                  }
                }
                this.isSiretChorus = true
              }
              this.listChorusStructureLoaded = true
              if (declencher === "initialize") {
                /// Réelement ICI ... ?
                this.updateStructureChorus(declencher + "_siretIsValid", this.groupement)
              }
              // In fine on relache !!!!
              this.listChorusStructureLoaded = true
            } else {
              // Aucun site Chorus n'a été trouvé
              this.$notification.warn({
                message: "Recherche Chorus",
                description: `Aucune structure n'a été trouvé dans la base chorus du siren à partir de ce siren`,
              })
              this.$notification.warn({
                message: "Recherche Chorus",
                description: `Aucune structure n'a pu être chargée depuis la base chorus du siren à partir de ce siren`,
              })
              this.listChorusStructureinBdd = []
              this.listChorusStructureActive = []
              this.isSiretChorus = false
              if (declencher !== "initialize") {
                this.chorusConfirmationCheck = false
              }
              this.form.setFieldsValue({
                engagementJuridique: null,
                codeServiceExecutant: null,
              })
            }
            // In fine on relache !!!!
            this.listChorusStructureLoaded = true
          })
          .catch((response) => {
            this.isSiretChorus = false
            this.$notification.error({
              message: "Erreur lors de la recherche Chorus",
              description: `Une erreur serveur est survenue lors la recherche du siren dans la base chorus`,
            })
            this.listChorusStructureLoaded = true
            this.$nextTick(() => {
              this.form.setFieldsValue({
                // chorusConfirmation: false,
                engagementJuridique: null,
                codeServiceExecutant: null,
                chorusNomStructureExecutant: null,
                codeSiretExecutant: null,
                chorusNomServiceExecutant: null,
                chorusCodeServiceExecutant: null,
              })
            })
            console.log("> Erreur : %O", response)
          })
      }
      // }
      console.groupEnd()
    },
    getListChorusStructureActive(listChorusStructureinBdd) {
      console.group("getListChorusStructureActive@GroupementsIterlocuteurForm")
      console.log("this.listChorusStructureinBdd : %O", this.listChorusStructureinBdd)
      console.log("listChorusStructureinBdd : %O", listChorusStructureinBdd)
      let res = []
      if (listChorusStructureinBdd.length > 0) {
        for (let key in this.listChorusStructureinBdd) {
          if (listChorusStructureinBdd[key].structureActive) {
            res.push(listChorusStructureinBdd[key])
          }
        }
        if (res.length === 0) console.log("Aucune structre Active chorus n'a été trouvée à partir du SIREN")
      } else {
        console.log("Aucune strucutre chorus n'a été trouvée à partir du SIREN")
      }
      console.groupEnd()
      return res
    },
    siretChorusIsValid(declencher, value) {
      console.group("siretChorusIsValid")
      console.log("> declencher value : %O %O", declencher, value)
      console.log("> value : %O", value.length)
      if (!this.chorusConfirmationCheck) {
        // Si pas en mode Chorus libre
        this.listChorusServiceInBdd = []
        this.listChorusServiceActif = []
        this.msgCodeServiceExecutant = ""
        if (declencher !== "initialize") {
          this.form.setFieldsValue({
            engagementJuridique: null,
            codeServiceExecutant: null,
            chorusNomServiceExecutant: null,
            chorusCodeServiceExecutant: null,
          })
        }
        // if(declencher==='Change_updateStructreChorus') debugger
        if (value.length === 14) {
          this.listChorusServiceLoaded = false
          console.log("this.listChorusServiceLoaded : %O", this.listChorusServiceLoaded)
          // By BDD
          // Axios("get", "api/chorusServices/search/findByIdentifiantStartingWith?identifiant=" + value)
          // By Chorus Live
          Axios("get", "chorus/services/" + value)
            .then((response) => {
              // By BDD
              // if (response.data._embedded.chorusServices && response.data._embedded.chorusServices.length > 0)
              // By Chorus live
              if (response.data && response.data.length > 0) {
                console.log("> Au moins un service trouvé en base")
                // Cas ou il y a des services déclarés dans Chorus
                // By BDD
                // this.listChorusServiceInBdd =  response.data._embedded.chorusServices;
                // By ChorusLive
                this.listChorusServiceInBdd = response.data
                // console.log("> this.chorusService.length : %O",this.chorusService.length)
                // console.log("> this.chorusService : %O",this.chorusService)
                this.listChorusServiceActif = this.getListChorusServiceActif(this.listChorusServiceInBdd)
                console.log("this.listChorusServiceActif : %O", this.listChorusServiceActif)
                if (this.listChorusServiceActif && this.listChorusServiceActif.length > 0) {
                  console.log("> SERVICE : PLURSIEUR SERVICE ACTIF")
                  // listChorusServiceActif non vide
                  // Et que code SE est non requis on ajoute le service _SANS_OBJET_CHOIX_VOLONTAIRE_
                  // pour permetre à l'utilisateur de ne pas saisir SE tout en l'obligeant de faire un choix
                  // if(!this.checkCodeServiceExecutantIsRequired(declencher + '_siretChorusIsValid')) this.addChorusServiceEmpty('_SANS_OBJET_CHOIX_VOLONTAIRE_')
                  // if(!(!this.structureChorusEnc.gestionEngagement && this.structureChorusEnc.gestionServiceEngagement)) this.addChorusServiceEmpty('_SANS_OBJET_CHOIX_VOLONTAIRE_')
                  if (!this.structureChorusEnc.gestionService)
                    this.addChorusServiceEmpty("_SANS_OBJET_CHOIX_VOLONTAIRE_")
                } else {
                  // listChorusServiceActif vide
                  console.log("> SERVICE : AUCUN SERVICE ACTIF")
                  this.addChorusServiceEmpty("_SANS_OBJET_PAS_DE_SERVICE_CHORUS_ACTIF_")
                }
                if (this.listChorusServiceActif.length === 1) {
                  console.log("> SERVICE : IN FINE UN SEUL SERVICE")
                  // Dans le cas ou il n'y a qu'un seul élément dans la liste
                  // on force aussitôt la valeur du gestionEgmt
                  this.listChorusServiceLoaded = true
                  console.log("this.listChorusServiceLoaded : %O", this.listChorusServiceLoaded)
                  // Et la valeur de chorusCodeServiceExecutant
                  this.$nextTick(() => {
                    this.form.setFieldsValue({
                      chorusNomServiceExecutant: this.listChorusServiceActif[0].nom,
                      chorusCodeServiceExecutant: this.listChorusServiceActif[0].code,
                    })
                  })
                  this.serviceChorusEnc = this.listChorusServiceActif[0]
                } else {
                  this.listChorusServiceLoaded = true
                  console.log("this.listChorusServiceLoaded : %O", this.listChorusServiceLoaded)
                  if (declencher !== "initialize_siretIsValid_updateStructreChorus") {
                    this.$nextTick(() => {
                      console.log(
                        "Efface dans l'IHM  chorusNomServiceExecutant, chorusCodeServiceExecutant, chorusCodeEngagementJuridique "
                      )
                      this.form.setFieldsValue({
                        engagementJuridique: null,
                        codeServiceExecutant: null,
                        chorusNomServiceExecutant: null,
                        chorusCodeServiceExecutant: null,
                        chorusCodeEngagementJuridique: null,
                      })
                    })
                  } else {
                    this.updateServiceChorus(declencher + "_siretChorusIsValid", this.groupement)
                  }
                }
              } else {
                // Cas ou il y a aucun service déclaré dans Chorus
                console.log("> Aucun Service trouvé en base")
                this.listChorusServiceActif = []
                this.addChorusServiceEmpty("_SANS_OBJET_CAR_PAS_DE_CODE_SERVICE_EN_BASE_CHORUS_")
                this.listChorusServiceLoaded = true
                console.log("this.listChorusServiceLoaded : %O", this.listChorusServiceLoaded)
                console.log("Set dans l'IHM  chorusNomServiceExecutant, chorusCodeServiceExecutant")
                this.$nextTick(() => {
                  console.log("Set dans l'IHM  chorusNomServiceExecutant, chorusCodeServiceExecutant")
                  this.form.setFieldsValue({
                    engagementJuridique: null,
                    codeServiceExecutant: null,
                    chorusNomServiceExecutant: this.listChorusServiceActif[0].nom,
                    chorusCodeServiceExecutant: this.listChorusServiceActif[0].code,
                  })
                })
                this.serviceChorusEnc = this.listChorusServiceActif[0]
              }
              this.listChorusServiceLoaded = true
              console.log("this.listChorusServiceLoaded : %O", this.listChorusServiceLoaded)
              console.groupEnd()
            })
            .catch((response) => {
              this.$notification.error({
                message: "Erreur lors de la recherche Chorus",
                description: `Une erreur serveur est survenue lors la recherche des services dans la base chorus`,
              })
              this.listChorusServiceLoaded = true
              console.log("this.listChorusServiceLoaded : %O", this.listChorusServiceLoaded)
              console.log("ERROR response : %O", response)
              console.groupEnd()
            })
        }
      }
    },
    getListChorusServiceActif(listChorusServiceInBdd) {
      console.group("getListChorusService@GroupementsInterlocuteurs")
      console.group("listChorusServiceInBdd : %O", listChorusServiceInBdd)
      let res = []
      if (listChorusServiceInBdd) {
        for (let key in listChorusServiceInBdd) {
          if (listChorusServiceInBdd[key].serviceActif) {
            res.push(listChorusServiceInBdd[key])
          }
        }
        if (res.length === 0) console.log("Aucun service Actif n'a été trouvée à partir du SIRET Chorus")
      } else {
        console.log("Aucun Service dans Chorus pour ce n°Siren...")
      }
      console.groupEnd()
      return res
    },
    // ----------------------------------------------
    // Interlocuteur
    prepareInterlocuteurBySite() {
      const interId = this.site && this.site.interlocuteurId ? this.site.interlocuteurId : null
      if (interId) {
        return Axios("get", "api/interlocuteurs/" + interId)
          .then((response) => {
            const siteInterlocuteur = _.find(this.interlocuteurs, ["id", response.data.id])
            if (siteInterlocuteur) {
              return Promise.resolve(siteInterlocuteur)
            } else {
              const interToPush = this.pushInterlocuteur(response.data)
              return Promise.resolve(interToPush)
            }
          })
          .catch(() => {
            return Promise.resolve(null)
          })
      }
      return Promise.resolve(null)
    },
    prepareInterlocuteurs() {
      Axios("get", "api/membres/" + this.membre.id + "/interlocuteurs")
        .then((response) => {
          const interlocuteurs = response.data._embedded.interlocuteurs || []
          interlocuteurs.forEach((inter) => {
            this.pushInterlocuteur(inter)
          })

          return this.interlocuteurs
        })
        .catch((error) => {
          console.log(
            `%c prepareInterlocuteurs error`,
            "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            error
          )
          return null
        })
    },
    pushInterlocuteur(inter) {
      if (inter.role === "FACTURATION" || inter.role === "GESTIONNAIRE" || inter.role === "AUTRE") {
        const interToPush = pick(inter, ["id", "fonction", "role", "nom", "prenom", "telephone", "mobile", "mail"])
        interToPush.friendly = `${inter.nom.toUpperCase()} ${_.capitalize(inter.prenom)}`

        this.interlocuteurs.push(interToPush)
        this.interlocuteurs = _.uniqBy(this.interlocuteurs, "id")
        return interToPush
      }
      return null
    },
    prepareInterlocuteurByMembre() {
      const interF = this.membre.interlocuteurFacturation || {}
      let interToReturn = null
      let interToPush = null
      if (interF && interF.nom && interF.prenom && (interF.telephone || interF.mobile) && interF.id) {
        interToPush = this.pushInterlocuteur(interF)
        interToReturn = interToPush
      }
      return Axios("get", "api/membres/" + this.membre.id + "/interlocuteurs")
        .then((response) => {
          for (const interF of response.data._embedded.interlocuteurs) {
            if (
              interF.nom &&
              interF.prenom &&
              (interF.telephone || interF.mobile) &&
              interF.id &&
              !_.find(this.interlocuteurs, ["id", interF.id]) &&
              (interF.role === "GESTIONAIRE" || interF.role === "FACTURATION" || interF.role === "AUTRE")
            ) {
              this.pushInterlocuteur(interF)
              interToReturn = interToReturn && interToReturn.id ? interToReturn : interToPush
            }
          }
        })
        .catch(() => {
          return Promise.resolve(null)
        })
        .finally(() => {
          return Promise.resolve(interToReturn)
        })
    },
    selectedInterlocuteurIdChange(interlocuteurPayeurId) {
      this.originalSelectedInterlocuteur = this.interlocuteurs.find((inter) => inter.id === interlocuteurPayeurId)
      this.setInterlocuteurPayeurSelected(this.originalSelectedInterlocuteur)
    },
    setInterlocuteurPayeurSelected(object) {
      console.log("setInterlocuteurPayeurSelected")
      const result = {}
      if (object.id) result.interlocuteurPayeurId = object.id
      if (object.nom) result.interlocuteurPayeurNom = object.nom
      if (object.prenom) result.interlocuteurPayeurPrenom = object.prenom
      if (object.mail) result.emailPayeur = object.mail
      if (object.telephone) result.telephonePayeur = object.telephone
      if (object.mobile) result.mobilePayeur = object.mobile
      if (object.selectedInterlocuteurId) result.selectedInterlocuteurPayeurId = object.selectedInterlocuteurId
      this.$nextTick(() => {
        this.form.setFieldsValue(result)
      })
    },
    // -- -----------------------------
    // Other
    chorusLive(value) {
      console.log("ChorusLive : " + value)
      Axios("get", "chorus/structures/" + value)
        .then((response) => {
          // handle success
          console.log(response)
        })
        .catch((error) => {
          // handle error
          console.log(error)
        })
    },
    // -- -----------------------------
    // Other
    resetForm() {
      console.log("resetForm")
      this.$nextTick(() => {
        this.originalSelectedInterlocuteur = {}
        this.form.setFieldsValue({
          interlocuteurPayeurNom: this.groupement.interlocuteurPayeurNom,
          interlocuteurPayeurPrenom: this.groupement.interlocuteurPayeurPrenom,
          emailPayeur: this.groupement.emailPayeur,
          telephonePayeur: this.groupement.telephonePayeur,
          mobilePayeur: this.groupement.mobilePayeur,
          selectedInterlocuteurId: this.groupement.interlocuteurPayeurId,
        })
      })
    },
    submit() {
      const interlocuteurKeys = [
        "interlocuteurPayeurNom",
        "interlocuteurPayeurPrenom",
        "emailPayeur",
        "telephonePayeur",
        "mobilePayeur",
      ]
      this.form.validateFields(interlocuteurKeys, (errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          this.$notification.error({
            message: "Vous avez des erreurs",
            description: `Veuillez remplir tous les champs comprenant des erreurs dans le formulaire de l'interlocuteur`,
          })
        } else {
          console.log("fieldsValue : %O", fieldsValue)
          console.log("originalSelectedInterlocuteur : %O", this.originalSelectedInterlocuteur)
          // if (!fieldsValue.selectedInterlocuteurId) {
          if (this.originalSelectedInterlocuteur === {}) {
            Axios("post", "api/interlocuteurs", {
              nom: fieldsValue.interlocuteurPayeurNom,
              prenom: fieldsValue.interlocuteurPayeurPrenom,
              telephone: fieldsValue.telephonePayeur,
              mobile: fieldsValue.mobilePayeur,
              mail: fieldsValue.emailPayeur,
              role: "FACTURATION",
              removed: false,
              membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
              operationId: this.operation.id,
            })
              .then((response) => {
                this.$notification.success({
                  message: "Interlocuteur payeur ajouté",
                  description: `${fieldsValue.interlocuteurPayeurPrenom} ${fieldsValue.interlocuteurPayeurNom} à bien été ajouter à vos interlocuteurs payeurs.`,
                })
                const interToPush = this.pushInterlocuteur(response.data)
                this.setInterlocuteurPayeurSelected({
                  selectedInterlocuteurId: interToPush.id,
                })
                this.saveForNextProcess(interToPush.id, fieldsValue)
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: "Erreur d'ajout de l'interlocuteur payeur",
                  description: `${this.getCapitalize(fieldsValue.interlocuteurPayeurPrenom)} ${this.getCapitalize(
                    fieldsValue.interlocuteurPayeurNom
                  )} n'as pas pu étre ajouté à vos interlocuteurs payeurs.`,
                })
              })
          } else {
            console.log("interlocuteur exist")
            this.saveForNextProcess(fieldsValue.selectedInterlocuteurId, fieldsValue)
          }
        }
      })
    },
    saveForNextProcess(interlocuteurId, interlocuteurFieldsValue) {
      const nextProcessKeys = [
        "siret",
        // "iban",
        // "moyenPaiement",
        // "delaiPaiement",
        "codeIdentificationIndividuelle",
        "numConventionTripartite",
        "engagementJuridique",
        "codeServiceExecutant",
        "state",
        "papier",
        "sepa",
        "rythmeFacturation",
        "optionExcel",
        "factureElectr",
        "emailEnvoi",
        "dematXml",
        "codeSiretExecutant",
        "chorusNomStructureExecutant",
        "chorusConfirmation",
        "dispoEdi",
        "chorusCodeServiceExecutant",
        "chorusCodeEngagementJuridique",
        "chorusNomServiceExecutant",
        "refContrat",
        "refMarche",
        "validChorus",
        "dataFacture",
      ]
      this.form.validateFields(nextProcessKeys, (errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          this.$notification.error({
            message: "Vous avez des erreurs",
            description: `Veuillez remplir tous les champs comprenant des erreurs dans le formulaire`,
          })
        } else {
          this.$emit("save", {
            interlocuteurId: interlocuteurId,
            interlocuteurFieldsValue: {
              ...interlocuteurFieldsValue,
              ...fieldsValue,
            },
          })
        }
      })
    },
    getSelectedInterlocuteurId() {
      return this.form.getFieldValue("selectedInterlocuteurId")
    },
    goTo(routeName) {
      this.$router.push({
        name: routeName,
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.switch-group {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  &.valid {
    .ant-col {
      .mcma-field.switch {
        .ant-form-item-label {
          text-align: left;
          flex-grow: 2;
          > label {
            color: red;
            font-weight: bold;
          }
        }
        .ant-form-item-control-wrapper {
          margin-left: calc(var(--padding, $padding) * 2);
        }
      }
    }
  }
  .ant-col {
    .mcma-field.switch {
      .ant-form-item-label {
        text-align: left;
        flex-grow: 2;
      }
      .ant-form-item-control-wrapper {
        margin-left: calc(var(--padding, $padding) * 2);
      }
    }
  }
}
</style>
